.login {
    background-color: white;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: auto;
}

.login__logo {
    margin-top: 20px;
    margin-bottom: 20px;
    object-fit: contain;
    width: 300px;
    margin-right: auto;
    margin-left: auto;
  }

.login__container {
    width: 300px;
    height: fit-content;
    display: flex;
    flex-direction: column;
    border-radius: 5px;
    border: 1px solid lightgray;
    padding: 20px;
}

.login__container > h1 {
    font-weight: 500;
    margin-bottom: 20px;
  }

.login__container > form > h5 {
    margin-bottom: 5px;
}

.login__container > form > input {
    height: 30px;
    margin-bottom: 10px;
    padding-left: 10px;
    background-color: white;
    width: 95%;
  }

.login__container > p {
    margin-top: 15px;
    font-size: 12px;
}

.login__signInButton {
    background: #11069E;
    color:white;
    border-radius: 2px;
    width: 100%;
    height: 30px;
    border: 1px solid;
    margin-top: 10px;
    
  }

  .login__registerButton {
    border-radius: 2px;
    width: 100%;
    height: 30px;
    border: 1px solid;
    margin-top: 10px;
    border-color: darkgray;
  }

  h1{
    color:#11069E;
    font-weight: 900;
  }