*{
  margin: 0;
  padding: 0;
 
 }

 @font-face {
  font-family: 'TeX Gyre Heros';
  src:  url('https://fonts.cdnfonts.com/css/tex-gyre-heros');

}