.checkout {
    display: flex;
    padding: auto;
    margin:auto;
    min-height: 300px;
    background-color: white;
    height: max-content;
    width:70%;
    justify-content: center;

  }
  
  .checkout__ad {
    width: 100%;
    margin-bottom: 10px;
  }


  
  .checkout__title {
    margin-right: 10px;
    padding: 10px;
    border-bottom: 1px solid lightgray;
  }

  .checkout__left {
    width: 100%;
    margin: 0 3rem 0 2rem;
  }

  .checkout__right {
    width: 50%;
    margin: 0 3rem 0 2rem;
  }