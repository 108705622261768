.home {
  display: flex;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  padding: 0 0 100px 0;
  /* background-color: #F4F2F5; */
}

.home__image {
  width: 100%;
  z-index: 1;
  
  /* margin-bottom: 150px; */
  /* mask-image: linear-gradient(to bottom, rgb(255, 255, 255), rgba(255, 255, 255, 0.9)); */
}

.home__container {
  background-image: linear-gradient(to bottom, rgb(255, 255, 255), rgba(255, 255, 255, 0.9));
  display: flex;
  flex-wrap: wrap; 
  justify-content: space-between;
  margin-left: auto;
  margin-right: auto;
  max-width: 1500px;
}

.home__title{
  
  color: #11069E;
  font-size: 2.3rem;
  font-weight: 800;
  display: flex;
  margin:auto;
  margin-top: 40px;
  margin-bottom: 50px;

}
.home__row_wrapper {
  display: flex;
  flex-wrap: wrap;
  gap: 0px 20px;
  justify-content: center; 
  padding: 0 10vw; 
  margin: 0 auto; 
  /* background-color:#F4F2F5 ; */
}

.home__row {
  flex: 0 0 calc(33.33% - 20px); 
  margin-bottom: 100px;
}

.product__section1{

  display: flex;
  justify-content: center;
  flex-direction: column;
}

.product__section2{
  background: #F4F2F5;
  display: flex;
  justify-content: center;
  flex-direction: column;
}